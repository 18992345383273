/* CSS for forbio */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href='#bottomnav'] {
  display: none;
}
:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

.not-for-ansatte {
  #main .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search), #main input[type="button"]:not(.red), #main input[type="submit"]:not(.submit-comment-button):not(.red), #main input[type="cancel"]:not(.red), #main button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red) {
    background-color: @color-secondary;
    .transition(all, 0.5s, ease-in);
    &:focus,  &:hover {
      background-color: @color-secondary--dark;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand,
    button {
      background-color: @color-contrast;
      .transition(all, 0.5s, ease-in);
      &:focus,  &:hover {
        background-color: @color-contrast--dark;
      }
    }
  }
  #head-wrapper {
    background: @color-light;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll 100% 53%;
    }
    #head {
      .uio-app-name { display: none; }
      height: 160px;
      .head-menu {
        right: 110px;
        .language { display: none;}
        a {
          color: @color-neutral--dark;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        #logo {
          position: absolute;
          top: 25px;
          span {
            color: @color-light;
            font-size: 25px;
            left: 220px;
            position: absolute;
            top: 42px;
            width: 550px;
          }
        }
      }
    }
  }
}
#globalnav {
  background: @color-primary--dark none repeat scroll 0 0;
  ul li {
    a, &.vrtx-active-item a {
      color: @color-light;
      &:hover, &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;

    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child a {
    color: @color-dark;
    background-color: @color-neutral--light;
  }
  a {
    &.vrtx-marked {
      background-color: @color-secondary !important;
      color: @color-dark;
      border: none;
    }
  }
}

table th { font-weight: bold;}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper, #footer-wrapper {
  background: @footer-color;
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-secondary;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png") no-repeat scroll right center / 22px auto;
      &:focus, &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link { display: none;}
    #head-wrapper {
      height: 178px;
      #head {
        height: 130px;
        #header #logo {
          padding-left: 20px;
          span {
            left:16px;
            top: 100px;
            font-size: 17px;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,  &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary          : #018BD6;
@color-primary--dark    : #0176b5;
@color-secondary        : #d0eaf6;
@color-secondary--dark  : darken(spin(@color-secondary, -5), 10%);
@color-contrast         : #009c49;
@color-contrast--dark   : #007e3b;
@color-link             : #2771bb;

// Neutral colors
@color-neutral--dark    : #2b2b2b;
@color-neutral          : #949494;
@color-neutral--light   : #EAEDEE;
@color-lines            : #F0F0F0;

// Suplementary colors
@color-light            : #ffffff;
@color-dark             : #000000;
@footer-color           : @color-neutral--light;

// Spacing
@unit                   : 5px;
@spacing--xlarge        : 100px;
@spacing--large         : 40px;
@spacing                : 20px;
@spacing--small         : 10px;
